import { NavLink } from "react-router-dom";
import MobileNav from "./MobileNav";

const navItems = [
  {
    name: "Home",
    path: "/",
  },
  {
    name: "About",
    path: "/about",
  },
  {
    name: "Projects",
    path: "/projects",
  },
  {
    name: "Contact",
    path: "/contact",
  },
];

function Navbar() {
  return (
    <nav className="w-full flex items-center justify-center fixed top-0 left-0 shadow-md bg-white">
      <div className="w-full max-w-7xl flex items-center justify-between px-5">
        <NavLink
          to="/"
          className="flex items-center font-mont text-4xl font-bold py-2"
        >
          JW
        </NavLink>
        <div className="md:flex items-center space-x-7 hidden">
          {navItems.map((item, index) => (
            <NavLink
              className={({ isActive }) =>
                isActive
                  ? "text-slate-900 border-b-2 border-slate-900 py-3 text-xl flex items-center font-semibold space-x-1"
                  : "text-slate-900 text-xl flex items-center py-3 font-semibold space-x-1"
              }
              to={item.path}
              key={index}
            >
              <p>{item.name}</p>
            </NavLink>
          ))}
        </div>
        <MobileNav />
      </div>
    </nav>
  );
}

export default Navbar;

// className={({ isActive }) =>
// isActive
//   ? "a"
//   : "b"
// }
