import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import {
  AiOutlineFolderOpen,
  AiOutlineHome,
  AiOutlineMail,
  AiOutlineMenu,
  AiOutlineQuestionCircle,
} from "react-icons/ai";
import { NavLink } from "react-router-dom";

export default function MobileNav() {
  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  return (
    <>
      <div className="md:hidden flex items-center justify-center">
        <button type="button" onClick={openModal} className="">
          <AiOutlineMenu className="text-2xl" />
        </button>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={closeModal}
        >
          <div className="min-h-screen text-center bg-neutral-900 bg-opacity-80 z-50">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents.
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span> */}
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full h-screen p-3 overflow-hidden text-left align-middle transition-all transform bg-white z-50 dark:bg-slate-900">
                <Dialog.Title as="h3" className="bg-white dark:bg-slate-900">
                  <div className="flex items-center space-x-3">
                    <h2 className="text-lg">Where would you like to go?</h2>
                  </div>
                </Dialog.Title>
                <div className="flex flex-col space-y-0 mt-5 rounded-md">
                  <NavLink
                    onClick={closeModal}
                    to="/"
                    className={({ isActive }) =>
                      isActive
                        ? "ring-2 rounded flex items-center space-x-3 hover:bg-slate-200 p-3 ring-slate-900 bg-slate-200 dark:ring-slate-200 dark:hover:bg-slate-200 dark:hover:text-slate-900"
                        : "hover:bg-slate-200 p-3 flex ring-2 rounded ring-transparent items-center space-x-3 dark:hover:bg-slate-200 dark:hover:text-slate-900"
                    }
                  >
                    <AiOutlineHome className="text-2xl" />
                    <p className="text-lg font-semibold">Home</p>
                  </NavLink>
                  <NavLink
                    onClick={closeModal}
                    to="/about"
                    className={({ isActive }) =>
                      isActive
                        ? "ring-2 rounded flex items-center space-x-3 hover:bg-slate-200 p-3 ring-slate-900 bg-slate-200 dark:ring-slate-200 dark:hover:bg-slate-200 dark:hover:text-slate-900"
                        : "hover:bg-slate-200 p-3 flex ring-2 rounded ring-transparent items-center space-x-3 dark:hover:bg-slate-200 dark:hover:text-slate-900"
                    }
                  >
                    <AiOutlineQuestionCircle className="text-2xl" />
                    <p className="text-lg font-semibold ">About</p>
                  </NavLink>
                  <NavLink
                    onClick={closeModal}
                    to="/projects"
                    className={({ isActive }) =>
                      isActive
                        ? "ring-2 rounded flex items-center space-x-3 hover:bg-slate-200 p-3 ring-slate-900 bg-slate-200 dark:ring-slate-200 dark:hover:bg-slate-200 dark:hover:text-slate-900"
                        : "hover:bg-slate-200 p-3 flex ring-2 rounded ring-transparent items-center space-x-3 dark:hover:bg-slate-200 dark:hover:text-slate-900"
                    }
                  >
                    <AiOutlineFolderOpen className="text-2xl" />
                    <p className="text-lg font-semibold">Projects</p>
                  </NavLink>
                  <NavLink
                    onClick={closeModal}
                    to="/contact"
                    className={({ isActive }) =>
                      isActive
                        ? "ring-2 rounded flex items-center space-x-3 hover:bg-slate-200 p-3 ring-slate-900 bg-slate-200 dark:ring-slate-200 dark:hover:bg-slate-200 dark:hover:text-slate-900"
                        : "hover:bg-slate-200 p-3 flex ring-2 rounded ring-transparent items-center space-x-3 dark:hover:bg-slate-200 dark:hover:text-slate-900"
                    }
                  >
                    <AiOutlineMail className="text-2xl" />
                    <p className="text-lg font-semibold">Contact</p>
                  </NavLink>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
