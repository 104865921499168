import { useState } from "react";
import emailjs from "emailjs-com";
import photo from "../assets/contact.svg";
import { SiLinkedin, SiGithub } from "react-icons/si";
import { AiOutlinePaperClip } from "react-icons/ai";
import { CopyToClipboard } from "react-copy-to-clipboard/lib/Component";

export default function Contact() {
  const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
  const templateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
  const userId = process.env.REACT_APP_EMAILJS_USER_ID;
  const copiedEmail = "Contact@jayswilder.io";
  const [copied, setCopied] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [messageSent, setMessageSent] = useState(false);
  const [loading, setLoading] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true);
    emailjs
      .sendForm(serviceId, templateId, e.target, userId)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => console.log(err));

    setName("");
    setEmail("");
    setMessage("");
    setMessageSent(true);
  };

  return (
    <div className="min-h-full w-full flex flex-col xl:flex-row xl:space-x-16 items-center justify-center bg-white py-24 px-3 lg:px-0">
      <div className="max-w-xs space-y-5 lg:space-y-11 flex flex-col justify-center items-center lg:block text-center mb-5 lg:mb-0">
        <h1 className="font-mont font-bold text-2xl lg:text-6xl">Contact</h1>
        <img src={photo} alt="Responsive Design" className="lg:w-full w-1/2" />
      </div>
      <form
        className="flex flex-col min-h-fit ring-zinc-500 ring-1 p-5 rounded-md text-sm lg:text-lg shadow-md space-y-3 max-w-md w-full"
        onSubmit={sendEmail}
      >
        <input type="hidden" name="contact_number" />
        <div className="flex flex-col space-y-1">
          <label for="name">Name</label>
          <input
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="bg-zinc-200 py-1 px-2 lg:py-2 lg:px-3 ring-1 rounded ring-zinc-300"
            type="text"
            name="name"
            placeholder="Please enter your name"
            required
          />
        </div>

        <div className="flex flex-col space-y-1">
          <label for="email">Email</label>
          <input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="bg-zinc-200 py-1 px-2 lg:py-2 lg:px-3 ring-1 rounded ring-zinc-300"
            type="email"
            name="email"
            placeholder="Please enter your email"
            required
          />
        </div>

        <div className="flex flex-col space-y-1">
          <label for="message">Message</label>
          <textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className="bg-zinc-200 py-1 px-2 lg:py-2 lg:px-3 ring-1 ring-zinc-300 rounded max-w-md"
            name="message"
            required
          />
        </div>
        <input
          disabled={loading}
          type="submit"
          value={messageSent ? "Message Sent!" : "Send"}
          className={`p-3 lg:text-xl tracking-wider rounded-md shadow-md ring-1 
          hover:shadow-lg hover:ring-2 ${
            messageSent
              ? "bg-green-800 text-white hover:bg-green-700 ring-green-700 hover:ring-green-600 italic"
              : "bg-slate-900 text-slate-300 hover:bg-slate-800 hover:text-slate-200  ring-slate-800 hover:ring-slate-300"
          }`}
        />
      </form>
      <div className="flex py-10 space-x-8 lg:space-x-0 lg:space-y-8 lg:flex-col">
        <a
          className="flex items-center justify-center flex-col space-y-1"
          href="https://www.linkedin.com/in/jay-wilder-80a1b41a6/"
          target="_blank"
        >
          <SiLinkedin className="text-4xl lg:text-5xl text-slate-900" />
          <p className="text-sm lg:text-base">LinkedIn</p>
        </a>
        <a
          className="flex items-center justify-center flex-col space-y-1"
          href="https://github.com/jayswilder"
          target="_blank"
        >
          <SiGithub className="text-4xl lg:text-5xl text-slate-900" />
          <p className="text-sm lg:text-base">GitHub</p>
        </a>
        <CopyToClipboard text={copiedEmail} onCopy={() => setCopied(true)}>
          <a
            className="flex items-center justify-center flex-col space-y-1 max-w-max"
            href="#"
          >
            <AiOutlinePaperClip className="text-4xl lg:text-5xl text-slate-900" />
            <p className="text-sm lg:text-base min-w-max">Copy Email</p>
          </a>
        </CopyToClipboard>
      </div>
    </div>
  );
}
