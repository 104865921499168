import React from "react";
import photo from "../assets/responsive.svg";

function Home() {
  return (
    <div className="min-h-full w-full items-center justify-center flex bg-home bg-cover bg-no-repeat bg-left-bottom py-24">
      <div className="flex flex-col justify-evenly p-5 h-full items-center w-full max-w-7xl xl:flex-row xl:justify-between space-y-10">
        <div className="w-full flex flex-col justify-center text-center space-y-4 xl:text-left">
          <h1 className="font-mont font-bold text-7xl leading-tight md:text-8xl lg:text-9xl xl:text-8xl">
            Jay Wilder
          </h1>
          <p className="text-zinc-500 text-xl md:text-3xl lg:text-5xl">
            Web Developer
          </p>
        </div>
        <div className="max-w-4/5 xl:w-3/4">
          <img src={photo} alt="Responsive Design" />
        </div>
      </div>
    </div>
  );
}

export default Home;
