import React from "react";

function Projects() {
  return (
    <div className="min-h-full w-full flex items-center justify-center bg-white py-24">
      <h1>Projects</h1>
    </div>
  );
}

export default Projects;
