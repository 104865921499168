import React from "react";
import {
  SiHtml5,
  SiCss3,
  SiJavascript,
  SiNodedotjs,
  SiReact,
  SiMysql,
  SiGithub,
  SiFirebase,
  SiExpress,
  SiMaterialui,
  SiTailwindcss,
} from "react-icons/si";
import photo from "../assets/aboutMe.svg";

const icons = [
  <SiHtml5 className="text-4xl lg:text-6xl text-zinc-500" />,
  <SiCss3 className="text-4xl lg:text-6xl text-zinc-500" />,
  <SiJavascript className="text-4xl lg:text-6xl text-zinc-500" />,
  <SiReact className="text-4xl lg:text-6xl text-zinc-500" />,
  <SiTailwindcss className="text-4xl lg:text-6xl text-zinc-500" />,
];
const moreIcons = [
  <SiNodedotjs className="text-4xl lg:text-6xl text-zinc-500" />,
  <SiGithub className="text-4xl lg:text-6xl text-zinc-500" />,
  <SiFirebase className="text-4xl lg:text-6xl text-zinc-500" />,
  <SiExpress className="text-4xl lg:text-6xl text-zinc-500" />,
  <SiMysql className="text-4xl lg:text-6xl text-zinc-500" />,
];

function About() {
  return (
    <div className="min-h-full w-full flex items-center justify-center bg-white py-24">
      <div className="px-5 space-y-10 flex flex-col xl:flex-row max-w-7xl justify-between">
        <div className="xl:w-1/2 flex flex-col items-center space-y-10">
          <img src={photo} alt="Responsive Design" />
          <h4 className="hidden xl:flex xl:text-4xl font-mont bg-zinc-100 max-w-max p-3 ring-1 ring-zinc-300 mb-8">
            ABOUT ME
          </h4>
        </div>
        <div className="xl:w-[45%] lg:space-y-8 space-y-5">
          <div className="space-y-2 ">
            <h4 className="text-xs lg:text-lg xl:hidden bg-zinc-100 max-w-max p-3 ring-1 ring-zinc-300 mb-8">
              ABOUT ME
            </h4>
            <h2 className="font-bold text-lg lg:text-3xl">
              I'm a Web Developer with a focus on crafting minimalistic &
              user-friendly experiences.
            </h2>
            <p className="text-sm lg:text-xl text-zinc-500">
              I'm very passionate and dedicated to my work. After attending{" "}
              <a
                className="text-blue-600"
                href="https://www.austincodingacademy.com"
                target="_blank"
              >
                Austin Coding Academy
              </a>{" "}
              for Full Stack Web Development, I have acquired the skills and
              knowledge necessary to make any project a success.
            </p>
          </div>
          <div className="lg:space-y-8 space-y-5">
            <div className="space-y-2">
              <h2 className="font-bold lg:text-3xl text-xl">Knowledge</h2>
              <p className="text-sm lg:text-xl text-zinc-500">
                I have a strong foundation in HTML, CSS, JavaScript, and React.
                I have experience with Node.js, Express, MySQL and Firebase.
              </p>
            </div>
            <div className="flex flex-col items-start justify-start space-y-5 lg:space-y-8">
              <div className="flex justify-start text-left space-x-3 lg:space-x-8">
                {icons.map((icon, index) => (
                  <p key={index} className="flex items-center justify-center">
                    {icon}
                  </p>
                ))}
              </div>
              <div className="flex justify-start text-left space-x-3 lg:space-x-8">
                {moreIcons.map((icon, index) => (
                  <p key={index} className="flex items-center justify-center">
                    {icon}
                  </p>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
